import { useState } from 'react';
import axios from 'axios';

const apiURL = '/recommend';
// const apiURL = '/recommend';

const LoadingSpinner = (
  <div role="status">
    <svg
      aria-hidden="true"
      className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-black"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
    <span class="sr-only">Loading...</span>
  </div>
);

function App() {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('movie');
  const [text, setText] = useState('');

  const [data, setData] = useState(null);

  const getRecommendations = async () => {
    const response = await axios.get(`${apiURL}`, {
      params: {
        type,
        text,
      },
    });
    console.log(response.data);
    setData(response.data);
    setLoading(false);
  };

  const makeFirstLetterUpperCase = (string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return '';
    }
  };
  //bg-[url('https://static.pexels.com/photos/1043/red-building-industry-bricks-large.jpg')] bg-cover
  return (
    <div className="w-full h-screen overflow-hidden bg-[#151515]">
      <div className="w-full h-full p-4 overflow-y-auto">
        <div className="w-full flex flex-col justify-center">
          <div className="max-w-md mx-auto">
            <h1 className="text-white opacity-80 text-4xl xxxs:text-5xl xxs:text-6xl md:text-6xl font-extrabold mt-6 md:mt-12 mb-12">
              Recommend What to Watch
            </h1>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setData(null);
                setLoading(true);
                getRecommendations();
              }}
            >
              <h3 className="text-white opacity-80 text-lg mb-2">
                What are you in the mood of?
              </h3>
              {/* <select
                id="category"
                className="mb-2 p-2 w-full text-lg"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="movie">Movie</option>
                <option value="series">Series</option>
                <option value="Movies or Series">Both Movies & Series</option>
              </select> */}
              <div className="xxs:flex justify-between md:flex-row mb-2">
                <div>
                  <input
                    id="movie"
                    type="radio"
                    name="category"
                    value="movie"
                    onChange={(e) => setType(e.target.value)}
                    checked={type === 'movie'}
                  />{' '}
                  <label
                    className="text-white opacity-80 text-sm md:text-lg ml-2"
                    for="movie"
                  >
                    Movie
                  </label>
                </div>
                <div>
                  <input
                    id="series"
                    type="radio"
                    name="category"
                    value="series"
                    onChange={(e) => setType(e.target.value)}
                    checked={type === 'series'}
                  />
                  <label
                    className="text-white opacity-80 text-sm md:text-lg ml-2"
                    for="series"
                  >
                    Series
                  </label>
                </div>
                <div>
                  <input
                    id="both"
                    type="radio"
                    name="category"
                    value="Movies or Series"
                    onChange={(e) => setType(e.target.value)}
                    checked={type === 'Movies or Series'}
                  />
                  <label
                    className="text-white opacity-80 text-sm md:text-lg ml-2"
                    for="both"
                  >
                    Both Movies & Series
                  </label>
                </div>
              </div>

              <div id="fetch" className="md:flex justify-between">
                <input
                  type="text"
                  className="w-full mr-2 placeholder-gray-500 text-lg"
                  placeholder="For example, 1980s scifi about space"
                  id="term"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
                <button
                  type="submit"
                  id="search"
                  className="w-full mt-2 md:mt-0 md:w-fit bg-white opacity-80 text-black"
                >
                  Find
                </button>
              </div>
            </form>
          </div>

          <div className="mt-8 mx-auto">{loading && LoadingSpinner}</div>
          {data && (
            <div className="mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {data.map((item) => {
                  if (item) {
                    if (item.Response) {
                      if (item.Response === 'False') {
                        return;
                      }
                    }
                    return (
                      <div
                        // dataAos="fade-up"
                        // dataAosDuration="1000"
                        // dataAosOnce
                        className="bg-lightOp rounded-lg shadow-lg p-4 max-w-xs"
                      >
                        {item.Poster !== 'N/A' && (
                          <div className="w-fit bg-lightOp mx-auto md:mx-0">
                            <img
                              src={item.Poster}
                              className="text-center"
                              alt={`${item.Title}-poster`}
                            ></img>
                          </div>
                        )}
                        <h2
                          classsName="font-bold text-xl"
                          style={{ fontSize: '2em', fontStyle: 'bold' }}
                        >
                          {item.Title}
                        </h2>
                        <p className="font-bold">{item.Year}</p>
                        {item.Type && item.Type !== 'N/A' && (
                          <p className="">
                            Category:{' '}
                            <span className="font-bold">
                              {makeFirstLetterUpperCase(item.Type)}
                            </span>
                          </p>
                        )}
                        {item.Genre !== 'N/A' && (
                          <p>
                            Genre:{' '}
                            <span className="font-bold">{item.Genre}</span>
                          </p>
                        )}
                        {item.Genre !== 'N/A' && <p>{item.Plot}</p>}
                      </div>
                    );
                  } else {
                    return <div></div>;
                  }
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
